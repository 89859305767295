/* eslint-disable max-len */
import { ReactComponent as circle } from '../../assets/images/circle.svg';
import { ReactComponent as hexa } from '../../assets/images/hexa.svg';
import { ReactComponent as triangle } from '../../assets/images/triangle.svg';
import { ReactComponent as cube } from '../../assets/images/cube.svg';

const data = [
    {
        image: circle,
        name: 'Веб-сайты и сервисы',
        descr: 'Дизайнеры бок о бок с разработчиками создают индивидуальные сайты и интерфейсы любой сложности и объёма.',
    },
    {
        image: hexa,
        name: 'Full-stack разработка',
        descr: 'Команда разработчиков осуществляет полный цикл разработки различных digital проектов. Frontend, backend, testing, support.',
    },
    {
        image: triangle,
        name: 'Мобильная разработка',
        descr: 'Занимаемся разработкой мобильных приложений для iOS и Android. Придумываем креативные и технологичные решения, подробно проектируем интерактивные интерфейсы.',
    },
    {
        image: cube,
        name: 'Цифровые двойники',
        descr: 'Наши специалисты учат машины решать бизнес задачи, становиться лучшими помощниками в принятии решений и действовать в критических ситуациях.',
    },
];

export default data;
