import React, { FC } from 'react';
import { META_URLS, SITE_CONTENT } from '../../appConstants';
import {
    BigHeader,
    BlockContent,
    Contacts,
} from '../../components';
import { FeedbackForm } from '../../containers';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';
// import feedbackPage from '../../containers/APP/_feedbackPage';

export const FeedbackPage: FC = () => {
    const { Page } = SITE_CONTENT;
    const { FeedbackPage } = META_URLS;

    return (
        <>
            <HelmetSeo
                title={Page[FeedbackPage].title}
                description={Page[FeedbackPage].description}
            />
            <BlockContent>
                <BigHeader title="Напишите нам" />

                <Contacts onFeedback />

                <FeedbackForm />
            </BlockContent>

        </>
    );
};
