import { IDataAction, IDataState, ActionTypes } from '../../types';

const initialState: IDataState = {
    data: [],
    loading: false,
    error: null,
};

const initialAction: IDataAction = {
    type: ActionTypes.GET_DATA,
};

export const firstReducer = (state = initialState, action: IDataAction = initialAction): IDataState => {
    switch (action.type) {
    case ActionTypes.GET_DATA:
        return {
            data: [],
            loading: true,
            error: null,
        };
    case ActionTypes.SET_DATA_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: null,
        };
    case ActionTypes.SET_DATA_ERROR:
        return {
            data: [],
            loading: false,
            error: action.payload,
        };
    default:
        return state;
    }
};
