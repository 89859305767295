import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { SITE_CONTENT, URL } from '../../appConstants';
import { RouteNames } from '../../router';
import {
    BigFeedback,
    BigHeader,
    BlockContent,
    ExternalLink,
    HTMLContent,
} from '../../components';
import { useRequest } from '../../hooks';
import { TNewsItem, TReqData, meta } from '../../types';
import { TdNewsData } from '../../store';
import { isLocalTest } from '../../utils';
import styles from './styles.module.scss';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';

export const NewsDetailPage: FC = () => {
    const { pathname } = window.location;
    const navigate = useNavigate();
    const [NewsItem, setNews] = useState<TNewsItem | undefined>(
        isLocalTest ? TdNewsData.results[0] : undefined,
    );

    const [meta, setMeta] = useState<meta>({ title: '', description: '' });

    const redirectPage404 = () => navigate(RouteNames.PAGE404, { replace: true });

    const {
        data: NewsItems,
        request,
        status,
    } = useRequest<TReqData<TNewsItem>>({
        errback: redirectPage404,
    });
    useEffect(() => {
        request({ url: URL.NEWS.GET });
    }, []);

    useEffect(() => {
        if (status === 'SUCCESS') {
            const pathnameUrlArr = pathname.split('/').pop()?.split('-');
            const slugFromUrl = pathnameUrlArr?.slice(1).join('-');

            if (pathnameUrlArr) {
                const t = NewsItems?.results.find((i) => i.id === +pathnameUrlArr[0]);

                if (t?.slug) {
                    if (pathnameUrlArr.length === 1 || slugFromUrl !== t?.slug) {
                        navigate(`${t?.id}-${t?.slug}`);
                    }
                }

                if (!t || !t.is_active) {
                    redirectPage404();
                } else {
                    const { Page, Meta } = SITE_CONTENT;
                    setNews(t);
                    setMeta({
                        title: Page.project(t?.title),
                        description: t?.description || Meta.description,
                    });
                }
            }
        }
    }, [status]);

    return (
        <>
            <HelmetSeo title={meta.title} description={meta.description} />
            <BlockContent contentClass={styles.articleWrapper}>
                <Link
                    className={styles.backLink}
                    to="/news"
                    title="Вернуться к списку новостей"
                >
                    Все статьи
                </Link>

                <div className={styles.article}>
                    <BigHeader
                        title={NewsItem?.title || ''}
                        className={styles.articleHead}
                    />

                    <div className={styles.newsMeta}>
                        <Moment date={NewsItem?.published} format="DD.M.YYYY" />
                        <ExternalLink className={styles.extLink} href={NewsItem?.link}>
                            Читать в источнике
                        </ExternalLink>
                    </div>

                    <HTMLContent content={NewsItem?.text || ''} />
                </div>
            </BlockContent>

            <BigFeedback />
        </>
    );
};
