import React, { FC } from 'react';
import styles from './styles.module.scss';
import { InfoItemProps } from './InfoItem.types';

export const InfoItem: FC<InfoItemProps> = ({ title, data }) => {
    if (!data) return null;

    const { description: descr = '', note = '' } = data;

    return !!descr.trim() || !!note.trim() ? (
        <div className={styles.infoItem}>
            <h3>{title}</h3>
            {descr && <div>{descr}</div>}
            {note && <span>{note}</span>}
        </div>
    ) : null;
};
