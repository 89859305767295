import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

export const ScrollToTopOnMount: FC = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto',
        });
    }, [pathname]);
    return null;
};
