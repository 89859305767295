import React from 'react';
import { Service, BlockContent } from '../../components';
import data from './_TestData';
import styles from './styles.module.scss';

export const Services = () => (
    <BlockContent
        id="Services"
        title="Услуги"
        // eslint-disable-next-line max-len
        descr="Мы предлагаем полный спектр услуг по проектированию и разработке сайтов и интерфейсов. Запускаем и развиваем ваши продукты."
    >
        <div className={styles.services}>
            {data.map((proj, i) => (
                <Service key={proj.name} item={proj} />
            ))}
        </div>
    </BlockContent>
);
