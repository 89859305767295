import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { InputWrapper } from '../../containers';
import { InputProps } from './Input.types';

export const Input: FC<InputProps> = ({
    item,
    placeholder,
    value,
    onChange = () => {},
    helpText,
    isError = false,
    reqired = false,
    max = 250,
}) => (
    <InputWrapper helpText={helpText} isError={isError}>
        <input
            className={cx(styles.input, { [styles.filled]: !!value })}
            id={item}
            name={item}
            type="text"
            onChange={onChange}
            value={value}
            placeholder={placeholder?.concat(reqired ? '*' : '')}
            maxLength={max}
            required={reqired}
        />
    </InputWrapper>
);
