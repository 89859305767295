import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

export const NewsSkeleton = ({ count }: { count: number }): ReactElement => (
    <>
        {Array(count)
            .fill(null)
            .map((_, i) => (
                <div key={crypto.randomUUID()}>
                    <div>
                        <Skeleton height={300} />
                    </div>
                    <h2>
                        <Skeleton height={50} />
                    </h2>
                    <p>
                        <Skeleton height={20} />
                    </p>
                </div>
            ))}
    </>
);
