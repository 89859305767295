import { Dispatch } from 'redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TodoActionTypes, ITodoAction } from '../../types';

const URL: string = 'https://jsonplaceholder.typicode.com/todos';

export const fetchTodo = (page: number = 1, limit: number = 10) => async (dispatch: Dispatch<ITodoAction>) => {
    try {
        dispatch({
            type: TodoActionTypes.FETCH_TODOS,
        });
        const response = await axios.get(URL, {
            params: {
                _page: page,
                _limit: limit,
            },
        });
        dispatch({
            type: TodoActionTypes.FETCH_TODOS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: TodoActionTypes.FETCH_TODOS_ERROR,
            payload: 'Ошибочка вышла',
        });
        toast.error('Ошибочка вышла');
    }
};

export function setTodoPage(page: number): ITodoAction {
    return {
        type: TodoActionTypes.SET_TODO_PAGE,
        payload: page,
    };
}
