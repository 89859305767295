import React, { FC } from 'react';
import { BlockContent, CaseItem } from '../../components';
import styles from './styles.module.scss';
import { CaseContentProps } from './CaseContent.types';

export const CaseContent: FC<CaseContentProps> = ({ item }) => (item.is_active ? (
    <BlockContent title={item?.title} descr={item?.description}>
        <div className={styles.caseContent}>
            {item.content_image.map((imgCont) => (
                <CaseItem item={imgCont} />
            ))}
        </div>
    </BlockContent>
) : null);
