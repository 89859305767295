import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import styles from './styles.module.scss';

const showOn = 500; // px

export const ScrollToTop = () => {
    const [top, setTop] = useState(window.scrollY);

    const handleNavigation = useCallback((e: Event) => {
        const { currentTarget } = e;
        if (currentTarget instanceof Window) {
            setTop(currentTarget.scrollY);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleNavigation);
        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <div
            className={cx(styles.scrollToTop, {
                [styles.notHover]: !isMobile,
                [styles.active]: top >= showOn,
            })}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
            <span className={styles.upText}>Наверх</span>
        </div>
    );
};
