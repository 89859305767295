import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router';
import { BlockContent } from '../BlockContent';
import styles from './styles.module.scss';
import { BigFeedbackProps } from './BigFeedback.types';

export const BigFeedback: FC<BigFeedbackProps> = ({ title = 'Напишите нам' }) => (
    <Link to={RouteNames.FEEDBACK} className={styles.linkBigFeedback}>
        <BlockContent wrapperClass={styles.bigFeedback} contentClass={styles.content}>
            {title}
        </BlockContent>
    </Link>
);
