/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
import React, { FC } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { BigPoster, BlockContent } from '../../components';
import image from '../../assets/images/toBeDel/main.webp';
import styles from './styles.module.scss';

export const Offer: FC = () => (
    <BlockContent wrapperClass={styles.offer} contentClass={styles.content}>
        <BlockContent wrapperClass={styles.offerText}>
            <h1 className={styles.title}>
                Мы разрабатываем веб-сайты, мобильные приложения и проектируем сложные
                онлайн-сервисы для бизнеса.
            </h1>
            <AnchorLink className={styles.arrowLink} href="#Projects">
                Смотреть проекты
            </AnchorLink>
        </BlockContent>

        <BigPoster className={styles.imgAnim} image={image} />
    </BlockContent>
);
