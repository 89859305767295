import React, { FC, PropsWithChildren } from 'react';
import { InView } from 'react-intersection-observer';
import cx from 'classnames';
import styles from './styles.module.scss';
import { BlockContentProps } from './BlockContent.types';

export const BlockContent: FC<PropsWithChildren<BlockContentProps>> = ({
    id,
    wrapperClass,
    contentClass,
    title,
    descr,
    children,
}) => (
    <div id={id} className={cx(wrapperClass, styles.blockWrapper)}>
        <div className={cx(contentClass, styles.blockContent)}>
            {(title || descr) && (
                <InView threshold={0.25} triggerOnce initialInView fallbackInView>
                    {({ inView, ref }) => (
                        <div
                            ref={ref}
                            className={cx(styles.inView, { [styles.anim]: inView })}
                        >
                            {title && <h2 className={styles.blockTitle}>{title}</h2>}
                            {descr && <div className={styles.blockDescr}>{descr}</div>}
                        </div>
                    )}
                </InView>
            )}
            {children}
        </div>
    </div>
);
