import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { SITE_CONTENT } from '../../appConstants';
import { phoneRefactor } from '../../utils';
import styles from './styles.module.scss';
import { ContactsProps } from './Contacts.types';

export const Contacts: FC<ContactsProps> = ({ className, onFeedback = false }) => {
    const { Phone, Mail, Socials } = SITE_CONTENT;

    return (
        <div className={cx(className, styles.contacts, { [styles.invert]: onFeedback })}>
            <div className={styles.mailNphone}>
                <a className={cx({ [styles.big]: onFeedback })} href={`mailto:${Mail}`}>
                    {Mail}
                </a>

                {!onFeedback && <a href={`tel:${Phone}`}>{phoneRefactor(Phone)}</a>}
            </div>
            <div className={styles.socials}>
                {Socials.map(
                    (Social) => Social.active && (
                        <Link key={Social.name} to={Social.link} target="_blank">
                            {Social.name}
                        </Link>
                    ),
                )}
            </div>
        </div>
    );
};
