import { toast } from 'react-toastify';
import { Header, Footer } from '../../containers';
import AppRouter from './appRouter';
import { ScrollToTop } from '../../components';

export const App = () => {
    toast.configure();

    return (
        <>
            <Header />
            <AppRouter />
            <Footer />

            <ScrollToTop />
        </>
    );
};
