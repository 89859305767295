import { IRoute } from '../types';
import {
    NotFoundPage, FeedbackPage, MainPage, NewsPage, PolicyPage,
} from '../pages';

export enum RouteNames {
    PAGE404 = '/oops-404',
    MAIN = '/',
    FEEDBACK = '/feedback',
    NEWS = '/news',
    POLICY = '/policy',
    // DETAIL = '/detail',
}

export const publicRoutes: IRoute[] = [
    {
        path: RouteNames.MAIN,
        component: MainPage,
    },
    {
        path: RouteNames.FEEDBACK,
        component: FeedbackPage,
    },
    {
        path: RouteNames.PAGE404,
        component: NotFoundPage,
    },
    {
        path: RouteNames.NEWS,
        component: NewsPage,
    },
    {
        path: RouteNames.POLICY,
        component: PolicyPage,
    },
];
