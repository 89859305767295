import React, { FC } from 'react';
import { InfoItem } from './InfoItem';
import { CircleLink } from './CircleLink';
import styles from './styles.module.scss';
import { ClientInfoProps } from './ClientInfo.types';

export const ClientInfo: FC<ClientInfoProps> = ({ data }) => {
    const { client, project, links } = data;

    return (
        <div className={styles.clientInfo}>
            <div className={styles.infoText}>
                <InfoItem title="Клиент" data={client} />
                <InfoItem title="О проекте" data={project} />
            </div>

            <div className={styles.circleLinksWrap}>
                <CircleLink href={links?.project_link} variant="ext" />
                <CircleLink href={links?.case_link} variant="be" />
            </div>
        </div>
    );
};
