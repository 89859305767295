import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NewsDetailPage, ProjectDetailPage } from '../../pages';
import { RouteNames, publicRoutes } from '../../router';
import { ScrollToTopOnMount } from '../../components';

const AppRouter = () => (
    <>
        <ScrollToTopOnMount />
        <Routes>
            {publicRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={<route.component />} />
            ))}
            <Route path="/news/*" element={<NewsDetailPage />} />
            <Route path="*" element={<ProjectDetailPage />} />
            <Route
                path={RouteNames.PAGE404}
                element={<Navigate replace to={RouteNames.PAGE404} />}
            />
        </Routes>
    </>
);

export default AppRouter;
