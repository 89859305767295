import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ExternalLinkProps } from './ExternalLink.types';

export const ExternalLink: FC<PropsWithChildren<ExternalLinkProps>> = ({
    className,
    href = '',
    title = '',
    children,
}) => (
    <a
        className={cx(styles.externalLink, className)}
        href={`//${href}`}
        title={title || href}
        target="_blank"
        rel="noreferrer"
    >
        {children || 'Внешняя ссылка'}
    </a>
);
