import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { CircleLinkProps } from './CircleLink.types';

export const CircleLink: FC<CircleLinkProps> = ({ href, variant }) => (href ? (
    <a
        className={cx(styles.circleLink)}
        aria-label={variant === 'ext' ? 'Ссылка на проект' : 'Ссылка на Behance'}
        target="_blank"
        rel="noreferrer"
        href={href}
    >
        <i className={styles[variant]} />
    </a>
) : null);
