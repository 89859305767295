import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, BlockContent } from '../../components';
import { RouteNames } from '../../router';
import siteLogo from '../../assets/images/cfs-logo.svg';
import styles from './styles.module.scss';

export const Header = () => (
    <BlockContent wrapperClass={styles.header} contentClass={styles.content}>
        <Link to={RouteNames.MAIN}>
            <img src={siteLogo} alt="" />
        </Link>

        <Menu />
    </BlockContent>
);
