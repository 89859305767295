/* eslint-disable camelcase */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { NextProjProps } from './NextProj.types';

export const NextProj: FC<NextProjProps> = ({ toItem }) => {
    const { link, project_cover } = toItem;

    return (
        <div className={styles.nextProject}>
            <img src={project_cover} alt="" />
            <a href={link || ''}>
                <span>Следующий проект</span>
            </a>
        </div>
    );
};
