import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ServiceProps } from './Service.types';

export const Service: FC<ServiceProps> = ({ item }) => {
    const { image: ImageComponent, name, descr } = item;

    return (
        <InView threshold={0.5} triggerOnce>
            {({ inView, ref }) => (
                <div
                    ref={ref}
                    className={cx(styles.serviceLink, { [styles.anim]: inView })}
                >
                    <ImageComponent className={styles.preview} />
                    <div className={styles.name}>{name}</div>
                    <div className={styles.descr}>{descr}</div>
                </div>
            )}
        </InView>
    );
};
