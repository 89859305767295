/* eslint-disable camelcase */
import React, { FC } from 'react';
import { InView } from 'react-intersection-observer';
import cx from 'classnames';
import styles from './styles.module.scss';
import { CaseItemProps } from './CaseItem.types';

export const CaseItem: FC<CaseItemProps> = ({ item }) => {
    const {
        types, image, video_url, is_active,
    } = item;

    return is_active && !video_url ? (
        <InView
            // threshold={.5}
            rootMargin="-250px 0px"
            triggerOnce
            initialInView
            fallbackInView
        >
            {({ inView, ref }) => (
                <div
                    ref={ref}
                    className={cx(styles.CaseItem, styles[types], {
                        [styles.anim]: inView,
                    })}
                >
                    <div className={styles.preview}>
                        <img src={image || ''} alt="" />
                    </div>
                </div>
            )}
        </InView>
    ) : null;
};
