/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useRequest } from '../../hooks';
import { IBudget, IService, TReqData } from '../../types';
import styles from './styles.module.scss';
import { InputWrapper } from '../../containers';
import { SelectParamProps } from './SelectParam.types';

export const SelectParam: FC<SelectParamProps> = ({
    item,
    title,
    url,
    value,
    onChange,
    helpText,
    isError = false,
    reqired = false,
}) => {
    const { data, request } = useRequest<TReqData<IService | IBudget>>();
    useEffect(() => {
        if (url) request({ url });
    }, [url]);

    return (
        <InputWrapper className={styles.field} helpText={helpText} isError={isError}>
            {/* <div className={styles.hiddenInput}>
        <input
          id={item}
          name={item}
          type='text'
          value={value}
          required={reqired}
        />
      </div> */}

            <h3 className={styles.title}>{title}</h3>

            <div className={styles.params}>
                {data?.results.map((dataItem, id) => (
                    <div
                        className={cx(styles.param, {
                            [styles.selected]: value === dataItem.id,
                        })}
                        key={dataItem.id}
                        onClick={() => onChange({
                            target: {
                                name: item,
                                value: dataItem.id,
                            },
                        })}
                    >
                        {dataItem.title}
                    </div>
                ))}
            </div>
        </InputWrapper>
    );
};
