import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { META_URLS, SITE_CONTENT, URL } from '../../appConstants';
import { BigFeedback, BigHeader, BlockContent } from '../../components';
import { News } from '../../containers';
import styles from './styles.module.scss';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';
import { useRequest } from '../../hooks';
import { TReqData, TTopicItem } from '../../types';
import { Topics, buttonAllTopic } from '../../components/Topics/Topics';
import { RouteNames } from '../../router';

export const NewsPage: FC = () => {
    const { Page } = SITE_CONTENT;
    const { NewsPage } = META_URLS;

    const { data, request: NewsReq } = useRequest<TReqData<TTopicItem>>();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const topicParamsFromUrl = searchParams.get('topic');

    const [topicActive, setTopicActive] = useState<TTopicItem | undefined>(undefined);

    useEffect(() => {
        NewsReq({ url: URL.TOPIC.GET });
    }, []);

    useEffect(() => {
        if (!data?.results) return;

        if (topicParamsFromUrl === null) {
            setTopicActive(buttonAllTopic);
        } else {
            const activeT = data?.results.find((t) => t.slug === topicParamsFromUrl);
            if (activeT) {
                setTopicActive(activeT);
            } else {
                navigate(RouteNames.PAGE404, { replace: true });
            }
        }
    }, [topicParamsFromUrl, data?.results]);

    const changeTopic = useCallback(
        (topic: TTopicItem) => {
            if (topic.slug) {
                setSearchParams(`topic=${topic.slug}`);
            } else setSearchParams('');
        },
        [setSearchParams],
    );

    return (
        <>
            <HelmetSeo
                title={Page[NewsPage].title}
                description={Page[NewsPage].description}
            />
            <BlockContent contentClass={styles.policyPage}>
                <BigHeader className={styles.title} title="Студия в СМИ" />

                <h3>{topicActive?.title}</h3>

                <Topics
                    topics={data?.results}
                    changeTopic={changeTopic}
                    topicFromUrl={topicParamsFromUrl}
                />

                <News topicId={topicActive?.id.toString()} />
            </BlockContent>

            <BigFeedback />
        </>
    );
};
