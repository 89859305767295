/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
/* eslint-disable max-len */

import {
    TAboutProject,
    // TApplication,
    TCase,
    TClient,
    TNewsItem,
    TReqData,
    TTags,
} from '../types';

export const TdCases: TReqData<TCase> = {
    count: 1,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            title: 'Моспром. Корпоративный сайт 1',
            description: 'Описание. Моспром. Корпоративный сайт 1',
            image_for_card:
                'https://cyberform.systems/case/621bb1c86dbb1ab132f77861_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC.png',
            link: 'https://cyberform.systems/mosprom-case',
            project_title: 'МОСПРОМ',
            project_cover:
                'https://cyberform.systems/project_cover/62121e048d911462dc707963_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC_1.jpg',
            created: '2022-05-13T09:16:38.955058Z',
            updated: '2022-05-13T09:16:38.955113Z',
            main_page: true,
            is_active: true,
            client: {
                id: 1,
                title: 'Клиент',
                description: 'АНО МОСПРОМ',
                note: 'Текст сноски',
            },
            about_project: {
                id: 1,
                title: 'О проекте',
                description: 'Главный помощник московского экспорта',
                note: 'Текст сноски о проекте',
            },
            external_link: {
                id: 1,
                project_link: 'https://cyberform.systems/buy-in',
                case_link: 'https://buy-in.prom.moscow/',
            },
            tags: [
                {
                    id: 1,
                    title: 'Design',
                    created: '2022-05-12T20:48:05.452032Z',
                    is_active: true,
                },
            ],
        },
        {
            id: 2,
            title: 'Корпоративный сайт 2',
            description: 'Описание. Корпоративный сайт 2',
            image_for_card:
                'https://cyberform.systems/case/621bb1c86dbb1ab132f77861_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC.png',
            link: 'https://cyberform.systems/mosprom-case',
            project_title: 'МОСПРОМ',
            project_cover:
                'https://cyberform.systems/project_cover/62121e048d911462dc707963_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC_1.jpg',
            created: '2022-05-13T09:16:38.955058Z',
            updated: '2022-05-13T09:16:38.955113Z',
            main_page: true,
            is_active: true,
            client: {
                id: 1,
                title: 'Клиент',
                description: 'АНО МОСПРОМ',
                note: 'Текст сноски',
            },
            about_project: {
                id: 1,
                title: 'О проекте',
                description: 'Главный помощник московского экспорта',
                note: 'Текст сноски о проекте',
            },
            external_link: {
                id: 1,
                project_link: 'https://cyberform.systems/buy-in',
                case_link: 'https://buy-in.prom.moscow/',
            },
            tags: [
                {
                    id: 1,
                    title: 'Design',
                    created: '2022-05-12T20:48:05.452032Z',
                    is_active: true,
                },
            ],
        },
        {
            id: 3,
            title: 'Корпоративный сайт 3',
            description: 'Описание. Корпоративный сайт 3',
            image_for_card:
                'https://cyberform.systems/case/621bb1c86dbb1ab132f77861_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC.png',
            link: 'https://cyberform.systems/mosprom-case',
            project_title: 'МОСПРОМ',
            project_cover:
                'https://cyberform.systems/project_cover/62121e048d911462dc707963_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC_1.jpg',
            created: '2022-05-13T09:16:38.955058Z',
            updated: '2022-05-13T09:16:38.955113Z',
            main_page: true,
            is_active: true,
            client: {
                id: 1,
                title: 'Клиент',
                description: 'АНО МОСПРОМ',
                note: 'Текст сноски',
            },
            about_project: {
                id: 1,
                title: 'О проекте',
                description: 'Главный помощник московского экспорта',
                note: 'Текст сноски о проекте',
            },
            external_link: {
                id: 1,
                project_link: 'https://cyberform.systems/buy-in',
                case_link: 'https://buy-in.prom.moscow/',
            },
            tags: [
                {
                    id: 1,
                    title: 'Design',
                    created: '2022-05-12T20:48:05.452032Z',
                    is_active: true,
                },
            ],
        },
        {
            id: 4,
            title: 'Корпоративный сайт 4',
            description: 'Описание. Корпоративный сайт 4',
            image_for_card:
                'https://cyberform.systems/case/621bb1c86dbb1ab132f77861_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC.png',
            link: 'https://cyberform.systems/mosprom-case',
            project_title: 'МОСПРОМ',
            project_cover:
                'https://cyberform.systems/project_cover/62121e048d911462dc707963_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC_1.jpg',
            created: '2022-05-13T09:16:38.955058Z',
            updated: '2022-05-13T09:16:38.955113Z',
            main_page: true,
            is_active: true,
            client: {
                id: 1,
                title: 'Клиент',
                description: 'АНО МОСПРОМ',
                note: 'Текст сноски',
            },
            about_project: {
                id: 1,
                title: 'О проекте',
                description: 'Главный помощник московского экспорта',
                note: 'Текст сноски о проекте',
            },
            external_link: {
                id: 1,
                project_link: 'https://cyberform.systems/buy-in',
                case_link: 'https://buy-in.prom.moscow/',
            },
            tags: [
                {
                    id: 1,
                    title: 'Design',
                    created: '2022-05-12T20:48:05.452032Z',
                    is_active: true,
                },
            ],
        },
    ],
};

export const TdCurCase: TCase = {
    id: 2,
    content_block_case: [
        {
            id: 2,
            content_image: [
                {
                    id: 1,
                    types: 'wide',
                    priority: '1',
                    image: 'https://cyberform.systems/images/6213de056c267d279b8e88dd_%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F_%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0.png',
                    video_url: '',
                    created_at: '2022-05-13T09:20:43.215527Z',
                    is_active: true,
                    content: 2,
                },
                {
                    id: 2,
                    types: 'half',
                    priority: '2',
                    image: 'https://cyberform.systems/images/6213de0460bab92dbf5fdfd4_%D0%91%D0%BB%D0%BE%D0%BA_%D0%BC%D0%B5%D1%80%D0%BE%D0%BF%D1%80%D0%B8%D1%8F%D1%82%D0%B8%D1%8F.png',
                    video_url: '',
                    created_at: '2022-05-13T09:20:43.218436Z',
                    is_active: true,
                    content: 2,
                },
                {
                    id: 3,
                    types: 'half',
                    priority: '2',
                    image: 'https://cyberform.systems/images/6213de0475502f1bdc29cf44_%D0%91%D0%BB%D0%BE%D0%BA_%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D0%B8.png',
                    video_url: '',
                    created_at: '2022-05-13T09:20:43.219517Z',
                    is_active: true,
                    content: 2,
                },
                {
                    id: 7,
                    types: 'video',
                    priority: '3',
                    image: null,
                    video_url: 'https://vimeo.com/manage/videos/707491208',
                    created_at: '2022-05-13T09:28:07.969946Z',
                    is_active: true,
                    content: 2,
                },
            ],
            title: 'О проекте',
            description:
                'АНО МОСПРОМ – главный помощник московского экспорта. Мы реализовали крупный корпоративный сайт на котором рядовой пользователь может ознакомиться с деятельностью компании, а экспортер найдёт для себя большой выбор мер поддержки.',
            created_at: '2022-05-13T09:16:38.973495Z',
            is_active: true,
            case: 2,
        },
        {
            id: 3,
            content_image: [
                {
                    id: 4,
                    types: 'wide',
                    priority: '1',
                    image: 'https://cyberform.systems/images/6213e0eac7810f297455cabf_%D0%A1%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0_%D0%BC%D0%B5%D1%80%D0%BE%D0%BF%D1%80%D0%B8%D1%8F%D1%82%D0%B8%D1%8F.jpg',
                    video_url: '',
                    created_at: '2022-05-13T09:22:47.122488Z',
                    is_active: true,
                    content: 3,
                },
                {
                    id: 5,
                    types: 'half',
                    priority: '2',
                    image: 'https://cyberform.systems/images/6213de04abe881234c92f601_%D0%9A%D0%B0%D1%80%D1%82%D0%BE%D1%87%D0%BA%D0%B8.png',
                    video_url: '',
                    created_at: '2022-05-13T09:22:47.129719Z',
                    is_active: true,
                    content: 3,
                },
                {
                    id: 6,
                    types: 'half',
                    priority: '2',
                    image: 'https://cyberform.systems/images/6213de04c7810f9bca55a9c1_%D0%9C%D0%BE%D0%B1_%D1%84%D0%B8%D0%BB%D1%8C%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F.png',
                    video_url: '',
                    created_at: '2022-05-13T09:22:47.134287Z',
                    is_active: true,
                    content: 3,
                },
                {
                    id: 12,
                    types: 'half',
                    priority: '2',
                    image: null,
                    video_url: '',
                    created_at: '2022-05-20T17:33:24.555027Z',
                    is_active: false,
                    content: 3,
                },
                {
                    id: 13,
                    types: 'half',
                    priority: '2',
                    image: null,
                    video_url: '',
                    created_at: '2022-05-20T17:33:24.557211Z',
                    is_active: false,
                    content: 3,
                },
                {
                    id: 14,
                    types: 'half',
                    priority: '2',
                    image: null,
                    video_url: '',
                    created_at: '2022-05-20T17:33:24.558992Z',
                    is_active: false,
                    content: 3,
                },
            ],
            title: 'Мероприятия',
            description:
                'В разделе мероприятий мы реализовали простую, но очень удобную структуру. Для поиска необходимого мероприятия пользователь может воспользоваться фильтром, который расположен в левой колонке. Карточка мероприятия располагает ключевыми данными, которые помогут выбрать мероприятие. Для подробного изучения, пользователь может провалиться на подробную страницу мероприятия и изучить всю информацию.',
            created_at: '2022-05-13T09:16:38.976598Z',
            is_active: true,
            case: 2,
        },
    ],
    title: 'Моспром. Корпоративный сайт',
    description: 'Описание. Моспром. Корпоративный сайт',
    image_for_card:
        'https://cyberform.systems/case/621bb1c86dbb1ab132f77861_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC.png',
    link: 'https://cyberform.systems/mosprom-case',
    project_title: 'МОСПРОМ',
    project_cover:
        'https://cyberform.systems/project_cover/62121e048d911462dc707963_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0_%D0%9C%D0%BE%D1%81%D0%BF%D1%80%D0%BE%D0%BC_1_JJT9bm3.jpg',
    created: '2022-05-13T09:16:38.955058Z',
    updated: '2022-05-20T17:45:38.792795Z',
    main_page: true,
    is_active: true,
    client: {
        id: 1,
        title: 'Клиент',
        description: 'АНО МОСПРОМ',
        note: 'Текст сноски',
    },
    about_project: {
        id: 1,
        title: 'О проекте',
        description: 'Главный помощник московского экспорта',
        note: 'Текст сноски о проекте',
    },
    external_link: {
        id: 1,
        project_link: 'https://cyberform.systems/buy-in',
        case_link: 'https://buy-in.prom.moscow/',
    },
    tags: [
        {
            id: 1,
            title: 'Design',
            created: '2022-05-12T20:48:05.452032Z',
            is_active: true,
        },
    ],
};

export const TdTags: TReqData<TTags> = {
    count: 1,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            title: 'Design',
            created: '2022-05-12T20:48:05.452032Z',
            is_active: true,
        },
        {
            id: 2,
            title: 'Design 2',
            created: '2022-05-12T20:48:05.452032Z',
            is_active: true,
        },
        {
            id: 3,
            title: 'Design 3',
            created: '2022-05-12T20:48:05.452032Z',
            is_active: true,
        },
        {
            id: 4,
            title: 'Design 4',
            created: '2022-05-12T20:48:05.452032Z',
            is_active: true,
        },
    ],
};

export const TdClients: TReqData<TClient> = {
    count: 1,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            title: 'Клиент',
            description: 'АНО МОСПРОМ',
            note: 'Текст сноски',
        },
    ],
};

export const TdAppl: TReqData<TAboutProject> = {
    count: 0,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            title: 'О проекте',
            description: 'Главный помощник московского экспорта',
            note: 'Текст сноски о проекте',
        },
    ],
};

export const TdNewsData: TReqData<TNewsItem> = {
    count: 6,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            slug: 'test-nazvanie-stati',
            title: 'Тест название статьи*',
            description: 'Тест краткое описание статьи*',
            published: '2022-10-01',
            text_about_link: 'источник статьи*',
            link: 'google.com',
            image: 'http://cyberform.systems/studio/cap.jpg',
            text: '<p><strong>Основной текст статьи*</strong></p>\r\n\r\n<p><strong><em>Основной текст статьи*</em></strong></p>\r\n\r\n<p><u><em><strong>Основной текст статьи*</strong></em></u></p>\r\n\r\n<p><em>Основной текст статьи*</em></p>\r\n\r\n<ul>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n</ul>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<ol>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n\t<li><strong>Основной текст статьи*</strong></li>\r\n</ol>\r\n\r\n<h2 class="headerL2"><strong>Основной текст статьи*</strong></h2>\r\n\r\n<h3 class="headerL3"><strong>Основной текст статьи*</strong></h3>\r\n\r\n<p class="articleText"><strong>Основной текст статьи*</strong></p>\r\n\r\n<p class="articleText">&nbsp;</p>\r\n\r\n<p><quote class="quoteText"><strong>Основной текст статьи*</strong></quote></p>\r\n\r\n<p><a href="http://google.com"><strong>Основной текст статьи*</strong></a></p>\r\n\r\n<p><span style="font-family:Comic Sans MS,cursive"><strong>Основной текст статьи*</strong></span></p>\r\n\r\n<p><span style="font-size:20px"><strong>Основной текст статьи*</strong></span></p>',
            created: '2022-10-12T11:11:32.603843Z',
            is_active: true,
        },
        {
            id: 2,
            slug: '2-povsednevnaya-nashej-igraet-uslovij-i-struktura',
            title: '2 Повседневная нашей играет условий и структура по представляет плановых высшего задания',
            description:
                '2 Обучения соображения показывает, заданий от оценить сфера позиций, развития. Формировании административных равным существенных собой идейные занимаемых финансовых в образом высшего условий требуют рам',
            is_active: true,
            published: '12.09.2022',
            image: 'string',
            link: 'http://vc.ru',
            text_about_link: 'vc.ru',
            created: '12.09.2022',
            text: '',
        },
        {
            id: 3,
            slug: '3-povsednevnaya-nashej-igraet-uslovij-i-struktura',
            title: '3 Повседневная нашей играет условий и структура по представляет плановых высшего задания',
            description:
                '3 Обучения соображения показывает, заданий от оценить сфера позиций, развития. Формировании административных равным существенных собой идейные занимаемых финансовых в образом высшего условий требуют рам',
            is_active: true,
            published: '12.09.2022',
            image: 'string',
            link: 'http://vc.ru',
            text_about_link: 'vc.ru',
            created: '12.09.2022',
            text: '',
        },
        {
            id: 4,
            slug: '4-povsednevnaya-nashej-igraet-uslovij-i-struktura',
            title: '4 Повседневная нашей играет условий и структура по представляет плановых высшего задания',
            description:
                '4 Обучения соображения показывает, заданий от оценить сфера позиций, развития. Формировании административных равным существенных собой идейные занимаемых финансовых в образом высшего условий требуют рам',
            is_active: true,
            published: '12.09.2022',
            image: 'string',
            link: 'http://vc.ru',
            text_about_link: 'vc.ru',
            created: '12.09.2022',
            text: '',
        },
        {
            id: 5,
            slug: '5-povsednevnaya-nashej-igraet-uslovij-i-struktura',
            title: '5 Повседневная нашей играет условий и структура по представляет плановых высшего задания',
            description:
                '5 Обучения соображения показывает, заданий от оценить сфера позиций, развития. Формировании административных равным существенных собой идейные занимаемых финансовых в образом высшего условий требуют рам',
            is_active: true,
            published: '12.09.2022',
            image: 'string',
            link: 'http://vc.ru',
            text_about_link: 'vc.ru',
            created: '12.09.2022',
            text: '',
        },
    ],
};
