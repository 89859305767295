import React, { FC } from 'react';
import { BlockContent, Contacts, PolicyBtn } from '../../components';
import styles from './styles.module.scss';

const s = 2022;
const f = new Date().getFullYear(); const Copy = () => (
    <div className={styles.copy}>
        <div>
            &copy;
            {' '}
            {s}
            {f !== s && ` - ${f}`}
            , ООО &quot;Кибер Форм Системс&quot;
        </div>
        <PolicyBtn />
    </div>
);
export const Footer: FC = () => (
    <BlockContent id="Contacts" wrapperClass={styles.footer}>
        <Contacts />
        <Copy />
    </BlockContent>
);
