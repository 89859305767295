// author: Victor K.
import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
    Input, InputFile, PolicyBtn, SelectParam,
} from '../../components';
import styles from './styles.module.scss';
import { InputErrors, URL } from '../../appConstants';
import { useRequest } from '../../hooks';
import { TApplication } from '../../types';
import { scrollToItem } from '../../helpers';
import { TString } from './FeedbackForm.types';

const fields: {
    nameKey: string;
    title: string;
    reqired?: boolean;
    max?: number;
}[] = [
    {
        nameKey: 'full_name',
        title: 'Имя',
        reqired: true,
        max: 100,
    },
    {
        nameKey: 'phone',
        title: 'Телефон',
        max: 12,
    },
    {
        nameKey: 'email',
        title: 'Email',
        reqired: true,
    },
    {
        nameKey: 'message',
        title: 'Опишите задачу',
    },
];

export const FeedbackForm = () => {
    const { request: sendData } = useRequest<TApplication>({
        callback: () => {
            // eslint-disable-next-line no-use-before-define
            resetForm();
            toast.success('Данные отправлены');
        },
    });

    const [file, setFile] = useState<'' | Blob>('');

    const validationSchema = Yup.object({
        full_name: Yup.string()
            .min(2, InputErrors.min(2))
            .max(100, InputErrors.max(100))
            .required(InputErrors.empty),
        phone: Yup.string()
            .length(12, InputErrors.phone_format)
            .matches(/(\+7)[0-9]{10}/, {
                message: InputErrors.phone_format,
                excludeEmptyString: false,
            }),
        email: Yup.string().email(InputErrors.email_format).required(InputErrors.email),
        services: Yup.number().required(InputErrors.service),
        budget: Yup.number().required(InputErrors.budget),
        message: Yup.string().max(250, InputErrors.max(250)),
    });

    const {
        resetForm,
        values,
        handleChange,
        handleSubmit,
        isValid,
        errors,
        status: formStatus,
        setStatus,
    } = useFormik({
        initialStatus: 'good',
        initialValues: (() => {
            const obj: TString = {};
            fields.forEach(({ nameKey }) => {
                obj[nameKey] = '';
            });
            obj.services = undefined;
            obj.budget = undefined;
            obj.file = '';
            return obj;
        })(),
        validationSchema,
        onSubmit: (values) => {
            const data = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                data.append(key, `${value}`);
            });
            data.set('file', file);

            sendData({
                method: 'POST',
                url: URL.APPLICATION.POST,
                data,
            });
        },
    });

    const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isValid) {
            setStatus('bad');
            scrollToItem('topForm');
            toast.error('Не все поля выбраны. Проверьте форму');
            return;
        }
        handleSubmit();
    };

    const isError: boolean = useMemo(() => formStatus === 'bad', [formStatus]);

    const changeFileHandler = (file: Blob) => {
        setFile(file);
    };

    return (
        <>
            <div id="topForm" />
            <form onSubmit={checkBeforeSubmit} className={styles.feedbackForm}>
                <SelectParam
                    item="services"
                    title="Какая услуга вас интересует*"
                    url={URL.APPLICATION.GET_SERVICE}
                    value={values.services}
                    onChange={handleChange}
                    helpText={errors.services}
                    isError={isError}
                    reqired
                />
                <SelectParam
                    item="budget"
                    title="Бюджет*"
                    url={URL.APPLICATION.GET_BUDGET}
                    value={values.budget}
                    onChange={handleChange}
                    helpText={errors.budget}
                    isError={isError}
                    reqired
                />

                {fields.map(({
                    nameKey, title, reqired, max,
                }) => (
                    <Input
                        key={nameKey}
                        placeholder={title}
                        item={nameKey}
                        value={values[nameKey]}
                        helpText={errors[nameKey]}
                        isError={isError}
                        onChange={handleChange}
                        max={max || 250}
                        reqired={!!reqired}
                    />
                ))}

                <InputFile name="file" onChange={changeFileHandler} />

                <div className={styles.assign}>
                    <input type="checkbox" name="assign" id="" required />
                    <label htmlFor="assign">
                        Нажимая отправить, вы соглашаетесь с
                        {' '}
                        <PolicyBtn title="политикой конфиденциальности" />
                    </label>
                </div>

                <div>
                    <button className={styles.submit} type="submit">
                        Отправить заявку
                    </button>
                </div>
            </form>
        </>
    );
};
