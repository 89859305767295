import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { BlockContent } from '../../components';
import styles from './styles.module.scss';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';
import { META_URLS, SITE_CONTENT } from '../../appConstants';

const PolUrl = 'contacts';

export const NotFoundPage: FC = () => {
    const { Page } = SITE_CONTENT;
    const { NotFoundPage } = META_URLS;

    return (
        <>
            <HelmetSeo
                title={Page[NotFoundPage].title}
                description={Page[NotFoundPage].description}
            />
            <BlockContent wrapperClass={styles.errPage}>
                <Link className={styles.link} to="/">
                    На главную
                </Link>

                <div className={styles.text}>
                    <span>Ошибка 404</span>
                    <span>Страница не найдена</span>
                </div>
            </BlockContent>
        </>
    );
};
