import React, { FC } from 'react';
import { META_URLS, SITE_CONTENT } from '../../appConstants';
import { BigFeedback } from '../../components';
import { Offer, Projects, Services } from '../../containers';
import { MainPageProps } from './MainPage.types';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';

export const MainPage: FC<MainPageProps> = () => {
    const { Page } = SITE_CONTENT;
    const { MainPage } = META_URLS;

    return (
        <>
            <HelmetSeo
                title={Page[MainPage].title}
                description={Page[MainPage].description}
            />
            <Offer />
            <Projects />
            <Services />
            <BigFeedback />
        </>
    );
};
