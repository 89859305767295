import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { InputWrapperProps } from './InputWrapper.types';

export const InputWrapper: FC<PropsWithChildren<InputWrapperProps>> = ({
    className,
    helpText,
    isError = false,
    children,
}) => (
    <div className={cx(styles.basicField, className || styles.field)}>
        {children}

        {helpText && (
            <div className={cx(styles.helpText, { [styles.error]: isError })}>
                {helpText}
            </div>
        )}
    </div>
);
