import axios from 'axios';

export const isLocalTest: boolean = window.location.href.includes('http://localhost');
const prodAdress: string = 'https://cyberform.systems/';

export const envSwitch: () => string = () => (isLocalTest ? prodAdress : process.env.REACT_APP_API_URL) as string;

export const apiClient = axios.create({
    baseURL: envSwitch(),
});
