import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

type ProjectsSkeletonProps = {
    count: number;
    className?: string;
};

export const ProjectsSkeleton: FC<ProjectsSkeletonProps> = ({ count, className }) => (
    <>
        {Array(count)
            .fill(null)
            .map((_, i) => (
                <div className={className} key={crypto.randomUUID()}>
                    <div>
                        <Skeleton className={styles.skeleton} duration={0} />
                    </div>
                    <div>
                        <Skeleton height={28} style={{ margin: '30px 0 14px' }} />
                    </div>
                    <div>
                        <Skeleton height={14} />
                    </div>
                </div>
            ))}
    </>
);
