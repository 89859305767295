export const scrollToItem = (targetEl: string, set?: boolean) => {
    if (set) {
        sessionStorage.setItem('scrollTo', targetEl);
    } else {
        setTimeout(() => {
            document.getElementById(targetEl)?.scrollIntoView({ behavior: 'smooth' });
        }, 200);
        sessionStorage.removeItem('scrollTo');
    }
};
