// author: Victor K.
import type { AxiosResponse } from 'axios';

export type TStringsObj = {
    [index: string]: any;
};

export type TNumericObj = {
    [index: number]: number | string;
};

export interface IBasicReq {
    count: number;
    next: string;
    previous: string;
}

export type TDigits = 'd' | 'h' | 'm' | 's';

export type THtmlContent = {
    content: string;
};

export type TApplies = {
    count: number;
    end_date: string;
};

export enum RequestStatus {
    INIT = 'INIT',
    REQUEST = 'REQUEST',
    RESET = 'RESET',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export type Response<T> = AxiosResponse<T>;
