import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../helpers';
import { AnchorLinkProps } from './AnchorLink.types';

export const AnchorLink: FC<PropsWithChildren<AnchorLinkProps>> = ({
    to,
    className,
    onClick,
    children,
}) => {
    const { pathname } = window.location;

    const clickHandler = () => {
        if (onClick) onClick();
        const targEl = to.split('#')[1];
        scrollToItem(targEl, pathname !== '/');
    };

    useEffect(() => {
        const item = sessionStorage.getItem('scrollTo');
        if (pathname === '/' && !!item) {
            scrollToItem(item);
        }
    });

    return (
        <Link className={className} to={to} onClick={() => clickHandler()}>
            {children}
        </Link>
    );
};
