/* eslint-disable camelcase */
// author: Victor K.
import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { BurgerProps } from './Burger.types';

export const Burger: FC<BurgerProps> = ({ isActive = false, onClick }) => (
    <div
        className={cx(styles.burger, { [styles.isActive]: isActive })}
        onClick={onClick}
    />
);
