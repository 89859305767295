import { combineReducers } from 'redux';
import { firstReducer } from './firstReducer';
import { todoReducer } from './todoReducer';

export const rootReducer = combineReducers({
    data: firstReducer,
    todo: todoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
