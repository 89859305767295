import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SITE_CONTENT, URL } from '../../appConstants';
import { RouteNames } from '../../router';
import {
    BigFeedback,
    BigHeader,
    BigPoster,
    BlockContent,
    ClientInfo,
    NextProj,
} from '../../components';
import { CaseContent } from '../../containers';
import cropLink from '../../helpers/cropLink';
import { useRequest } from '../../hooks';
import { TCase, TReqData, meta } from '../../types';
import { TdCurCase } from '../../store';
import { isLocalTest } from '../../utils';
import styles from './styles.module.scss';
import { HelmetSeo } from '../../components/HelmetSeo/HelmetSeo';

export const ProjectDetailPage: FC = () => {
    const { pathname } = window.location;
    const navigate = useNavigate();
    const [caseData, setCase] = useState<TCase | undefined>(
        isLocalTest ? TdCurCase : undefined,
    );

    const [meta, setMeta] = useState<meta>({ title: '', description: '' });

    const redirectPage404 = () => navigate(RouteNames.PAGE404, { replace: true });

    const {
        data: Cases,
        request,
        status,
    } = useRequest<TReqData<TCase>>({
        errback: redirectPage404,
    });
    useEffect(() => {
        request({ url: URL.CASE.GET });
    }, []);

    useEffect(() => {
        if (status === 'SUCCESS') {
            const t = Cases?.results.find((i) => cropLink(i.link) === pathname);
            if (!t || !t.is_active) {
                redirectPage404();
            } else {
                const { Page, Meta } = SITE_CONTENT;
                setCase(t);
                setMeta({
                    title: Page.project(t?.title),
                    description: t?.description || Meta.description,
                });
            }
        }
    }, [status]);

    return (
        <>
            <HelmetSeo title={meta.title} description={meta.description} img={caseData?.project_cover} />
            <BlockContent wrapperClass={styles.headWrapper}>
                <BigHeader title={caseData?.project_title || ''} />
                <ClientInfo
                    data={{
                        client: caseData?.client,
                        project: caseData?.about_project,
                        links: caseData?.external_link,
                    }}
                />
            </BlockContent>

            <BlockContent wrapperClass={styles.posterWrap} contentClass={styles.poster}>
                <BigPoster image={caseData?.project_cover} />
            </BlockContent>

            {caseData?.content_block_case?.map((item) => <CaseContent item={item} key={item.id} />)}

            {!isLocalTest && Cases && Cases.count > 1 && (
                <NextProj
                    toItem={
                        Cases.results.filter((item) => item.id !== caseData?.id)[
                            Math.floor(Math.random() * (Cases.count - 1))
                        ]
                    }
                />
            )}

            {isLocalTest && caseData && <NextProj toItem={caseData} />}

            <BigFeedback />
        </>
    );
};
