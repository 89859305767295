// author: Victor K.
import { TStringsObj } from '../types';

export const InputErrors: TStringsObj = {
    min: (num: number) => `Не может быть короче ${num} символов`,
    max: (num: number) => `Не может быть длиннее ${num} символов`,
    empty: 'Поле не может быть пустым',
    text: 'Введите текст',
    phone: 'Введите номер телефона',
    phone_format: 'Номер в формате +79999999999',
    email: 'Введите адрес почты',
    service: 'Выберите значение',
    budget: 'Выберите значение',
    email_format: 'Некорректный адрес почты',
    select: 'Выберите значение',
    checkbox: 'Выберите значение',
    radio: 'Выберите значение',
};

export const Placeholders: TStringsObj = {
    def: 'Введите значение',
    text: 'Введите текст',
    phone: 'Введите номер телефона',
    email: 'Введите адрес почты',
    select: 'Выберите значение',
    checkbox: 'Выберите значение',
    service: 'Выберите значение',
    budget: 'Выберите значение',
    radio: 'Выберите значение',
};
