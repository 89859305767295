import { FC, memo } from 'react';
import cx from 'classnames';
import { TTopicItem } from '../../types';
import styles from './styles.module.scss';
import { TopicsProps } from './Topics.types';

export const buttonAllTopic: TTopicItem = {
    id: '',
    is_active: true,
    list_position: 0,
    slug: null,
    title: 'Все',
};

export const Topics: FC<TopicsProps> = memo(
    ({ topics, changeTopic, topicFromUrl }: TopicsProps) => {
        const activeTopics = topics?.filter((t) => t.is_active === true);

        const allTopics: TTopicItem[] | undefined = activeTopics
            ? [buttonAllTopic, ...activeTopics]
            : undefined;

        return (
            <div className={styles.topicContainer}>
                {allTopics?.map((topic) => (
                    <button
                        key={`topic${topic.id}`}
                        type="button"
                        onClick={() => changeTopic(topic)}
                        className={cx(styles.btn, { [styles.btnActive]: topicFromUrl === topic.slug })}
                    >
                        {topic.title}
                    </button>
                ))}
            </div>
        );
    },
);
