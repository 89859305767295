// author: Victor K.
import { TStringsObj, TSocial } from '../types';

const DefTitle = 'Digital-продакшен CyberForm Systems';
export const DefDescription = 'Разрабатываем сложные онлайн-сервисы для бизнеса, сопровождаем веб-проекты.';

const Domain: string = 'https://cyberform.systems';
const Mail: string = 'request@cyberform.systems';
const Phone: string = '+79771200123';

const Socials: TSocial[] = [
    {
        name: 'Telegram',
        link: '//t.me/cyberformsystems',
        image: '',
        active: true,
    },
    {
        name: 'Vkontakte',
        link: '//vk.com/cfs_design',
        image: '',
        active: true,
    },
    {
        name: 'Behance',
        link: '//behance.net/cyberformsystems',
        image: '',
        active: true,
        // },{
        //   name: 'Dribbble',
        //   link: '//dribbble.com/',
        //   image: '',
        //   active: true,
    },
];

const Meta: {
    title: string;
    description: string;
} = {
    title: DefTitle,
    description: DefDescription,
};

const Page: TStringsObj = {
    '/': {
        title: DefTitle,
        description: DefDescription,
    },
    '/policy': {
        title: `Политика конфиденциальности | ${DefTitle}`,
        description: 'Политика конфиденциальности',
    },
    '/contacts': {
        title: `Начать работать с нами | ${DefTitle}`,
        description: 'Наши контакты',
    },
    '/news': {
        title: `О Нас в СМИ | ${DefTitle}`,
        description: 'Новости и статьи о нашей компании в СМИ',
    },
    '/oops-404': {
        title: `Страница не найдена | ${DefTitle}`,
        description: 'Страница, которую вы ищете, не существует',
    },

    project: (title: string) => `${title} | ${DefTitle}`,
};

export const META_URLS = {
    MainPage: '/',
    FeedbackPage: '/contacts',
    NewsPage: '/news',
    PolicyPage: '/policy',
    NotFoundPage: '/oops-404',
};

export const SITE_CONTENT = {
    Meta,
    Domain,
    Mail,
    Phone,
    Socials,
    Page,
};
