/* eslint-disable camelcase */
import React, { FC } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { ExternalLink } from '../ExternalLink';
import styles from './styles.module.scss';
import { NewsCardProps } from './NewsCard.types';

export const NewsCard: FC<NewsCardProps> = ({ newsItem }) => {
    const {
        id, slug, title, description, published, image, link, text_about_link,
    } = newsItem;

    const urlNews = slug ? `${id}-${slug}` : `${id}`;

    return (
        <div className={styles.newsCard}>
            <Link className={styles.newsLink} to={urlNews} title={title}>
                <img src={image} alt={title} className={styles.newsPoster} />
                <h2 className={styles.newsTitle}>{title}</h2>
                <span className={styles.newsDescription}>{description}</span>
            </Link>
            <div className={styles.newsMeta}>
                <Moment date={published} format="DD.M.YYYY" />
                <ExternalLink className={styles.extLink} href={link} title={link}>
                    {text_about_link}
                </ExternalLink>
            </div>
        </div>
    );
};
