import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router';
import { PolicyBtnProps } from './PolicyBtn.types';

export const PolicyBtn: FC<PolicyBtnProps> = ({ title, className }) => (
    <Link className={className} to={RouteNames.POLICY}>
        {title || 'Политика конфиденциальности'}
    </Link>
);
