// author: Victor K.
import {
    FC, useRef, ChangeEvent, useState,
} from 'react';
// import { Placeholders } from '../../appConstants';
// import { TextField } from '../../components';
import styles from './styles.module.scss';
import { InputFileProps } from './InputFile.types';

export const InputFile: FC<InputFileProps> = ({
    name = 'default',
    value,
    placeholder,
    title,
    error,
    required = false,
    onChange,
}) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>('');

    const onChangeLocal = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = inputEl?.current?.files?.[0];
        if (!file) {
            return;
        }
        const File: File = file;
        // const File2:Blob = File;
        if (!onChange) {
            return;
        }
        onChange(File);

        if (!File) {
            return;
        }
        setFileName(File.name);
    };

    const format = (str: string) => {
        if (str.length > 35) {
            return `${str.substring(0, 20)}...${str.substring(str.length - 12)}`;
        }
        return str;
    };

    return (
        <div className={styles.field_file}>
            <label htmlFor={name}>
                + Прикрепить файл
                <input
                    className={styles.input}
                    type="file"
                    name={name}
                    id={name}
                    ref={inputEl}
                    required={required}
                    accept=".png,.jpg,.jpeg,.doc,.docx,.pdf"
                    onChange={(e) => onChangeLocal(e)}
                />
            </label>

            {fileName && (
                <div className={styles.fileName}>
                    Прикреплен файл:
                    {' '}
                    {format(fileName)}
                </div>
            )}
        </div>
    );
};
