/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import './styles.css';
import { HTMLContentProps } from './HTMLContent.types';

export const HTMLContent: FC<HTMLContentProps> = ({ content, className }) => (
    <div
        className={cx(styles.section, className)}
        dangerouslySetInnerHTML={{ __html: content }}
    />
);
