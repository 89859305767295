import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { AnchorLink } from '../../../components';
import { RouteNames } from '../../../router';
import styles from './styles.module.scss';
import { MenuLinksProps } from './MenuLinks.types';

const MenuItems = [
    {
        title: 'Проекты',
        href: '/#Projects',
    },
    {
        title: 'Услуги',
        href: '/#Services',
    },
    {
        title: 'Контакты',
        href: '/#Contacts',
    },
];

export const MenuLinks: FC<MenuLinksProps> = ({ openMobile = false, onClick }) => (
    <div className={cx(styles.menu, { [styles.openMobile]: openMobile })}>
        {MenuItems.map((link) => (
            <AnchorLink
                key={link.title}
                className={styles.menuItem}
                to={link.href}
                onClick={onClick}
            >
                {link.title}
            </AnchorLink>
        ))}

        <Link
            className={styles.topFeedback}
            to={RouteNames.FEEDBACK}
            onClick={onClick}
        >
            Обсудить проект
        </Link>
    </div>
);
