/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import cx from 'classnames';
import styles from './styles.module.scss';
import cropLink from '../../helpers/cropLink';
import { ProjectProps } from './Project.types';

export const Project: FC<ProjectProps> = ({ item, className }) => {
    const {
        link, image_for_card, title, tags,
    } = item;

    return (
        <InView threshold={0.5} triggerOnce initialInView fallbackInView>
            {({ inView, ref }) => (
                <Link
                    ref={ref}
                    className={cx(className, styles.projectLink, {
                        [styles.anim]: inView,
                    })}
                    to={cropLink(link)}
                >
                    <div className={styles.preview}>
                        <img src={image_for_card} alt="" />
                    </div>
                    <div className={styles.name}>{title}</div>
                    <div className={styles.tag}>
                        {tags.map((item, id) => `${id === 0 ? '' : ', '}${item.title}`)}
                    </div>
                </Link>
            )}
        </InView>
    );
};
