// author: Victor K.
export const URL = {
    ROOT: '/',
    ABOUT_PROJECT: {
        GET: '/api/about-project/',
    },
    APPLICATION: {
        POST: '/api/application/',
        GET_BUDGET: '/api/budget/',
        GET_SERVICE: '/api/service/',
    },
    CASE: {
        GET: '/api/case/',
        GET_ID: (id: number) => `/api/case/${id}/`,
    },
    CLIENT: {
        GET: '/api/client/',
    },
    NEWS: {
        GET: '/api/studio/',
        GET_ID: (id?: number | string) => `/api/studio/${id}/`,
        GET_TOPIC: (topicId: string) => `/api/studio/?topic=${topicId}`,
    },
    TOPIC: {
        GET: '/api/topic/',
    },
    TAGS: {
        GET: '/api/tags/',
    },

    TEST: {
        GET: 'https://randomuser.me/api/',
    },
};
