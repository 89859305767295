import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { HelmetSeoProps } from './HelmetSeoProps.types';
import image from '../../assets/images/toBeDel/main.webp';
import { DefDescription, SITE_CONTENT } from '../../appConstants';

export const HelmetSeo: FC<HelmetSeoProps> = ({ title, description, img }) => {
    const currentUrl = window.location.href;
    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:site_name" content={DefDescription} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:image" content={img || image} />
            <meta property="og:image:alt" content="Cyberform Banner" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:locale" content="ru_RU" />
            <meta property="og:og:site_name" content={SITE_CONTENT.Domain} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={currentUrl} />
            <meta name="twitter:image" content={img || image} />
            <meta name="twitter:image:alt" content="Cyberform Banner" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />

        </Helmet>
    );
};
