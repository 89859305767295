/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
import React, { FC, useEffect } from 'react';
import { NewsCard } from '../../components';
import styles from './styles.module.scss';
import { useRequest } from '../../hooks';
import { TNewsItem, TReqData } from '../../types';
import { URL } from '../../appConstants';
import { NewsProps } from './News.type';
import { NewsSkeleton } from './NewsSkeleton';

export const News: FC<NewsProps> = ({ topicId }) => {
    // const initMax = 6;
    // const [ showMore, setShowMore ] = useState<boolean>(false);

    const {
        data: NewsData,
        request: NewsReq,
        status,
    } = useRequest<TReqData<TNewsItem>>();
    useEffect(() => {
        if (topicId === undefined) return;
        NewsReq({ url: topicId ? URL.NEWS.GET_TOPIC(topicId) : URL.NEWS.GET });
    }, [topicId]);

    return (
        <div className={styles.newsList}>
            {status !== 'SUCCESS' ? (
                <NewsSkeleton count={10} />
            ) : (
                NewsData?.results.map((newsItem) => (
                    <NewsCard key={`News_${newsItem.id}`} newsItem={newsItem} />
                ))
            )}
        </div>
    );
};
