import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { BigPosterProps } from './BigPoster.types';

export const BigPoster: FC<BigPosterProps> = ({
    image,
    // video,
    className,
}) => (
    <div className={cx(styles.bigImg, className)}>
        <img src={image} alt="" />
    </div>
);
