import React, { useEffect, useState } from 'react';
import { URL } from '../../appConstants';
import { Project, BlockContent } from '../../components';
import { useRequest } from '../../hooks';
import { TCase, TReqData } from '../../types';
import styles from './styles.module.scss';
import { ProjectsSkeleton } from './ProjectsSkeleton';

export const Projects = () => {
    const initMax = 6;
    const [showMore, setShowMore] = useState<boolean>(false);

    const { data: CaseData, request: CaseReq, status } = useRequest<TReqData<TCase>>();

    useEffect(() => {
        CaseReq({ url: URL.CASE.GET });
    }, []);

    return (
        <BlockContent
            id="Projects"
            title="Проекты"
            descr="Мы не приемлем шаблонность, поэтому в каждом нашем проекте выкладываемся по максимуму."
        >
            <div className={styles.projects}>
                {status !== 'SUCCESS' && <ProjectsSkeleton count={initMax} className={styles.projectItem} />}
                {CaseData?.results?.map(
                    (proj, id) => (showMore || id < initMax) && (
                        <Project
                            className={styles.projectItem}
                            key={proj.id}
                            item={{
                                ...proj,
                            }}
                        />
                    ),
                )}
            </div>

            {!!CaseData?.results && CaseData?.results?.length > initMax && !showMore && (
                <div className={styles.showMore} onClick={() => setShowMore(true)}>
                    Смотреть все проекты
                </div>
            )}
        </BlockContent>
    );
};
